<template>
	<div>
		<h1>Editar Cliente</h1>

		<v-progress-circular v-if="loading" size="100" color="purple" indeterminate />

		<v-form v-if="form.cnpj" :disabled="searching" ref="form" @submit.prevent="save()">
      <v-progress-linear color="purple" v-if="saving" indeterminate />
      <v-row>	
        <v-col cols="12" md="6">
          <v-text-field
            label="CNPJ/CPF*"
            v-model="form.cnpj"
            :rules="[
              ...requiredRules,
              v => v && v.length <= 14 || 'Máximo 14.',
              v => v && v.length >= 11 || 'Mínimo 11.',
            ]"
            append-outer-icon="mdi-account-search"
            counter="14"
            :loading="requesting"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            label="Nome do cliente*"
            :rules="requiredRules"
            v-model="form.name"
          />
        </v-col>
      </v-row>
      <v-row>
				<v-col cols="12" md="4">
					<v-text-field
						label="TDA"
						v-model="form.tda"
					></v-text-field>
				</v-col>
				<v-col cols="12" md="4">
					<v-text-field
						label="TDE"
						v-model="form.tde"
					></v-text-field>
				</v-col>
				<v-col cols="12" md="4">
					<v-text-field
						label="Taxas"
						v-model="form.fees"
					></v-text-field>
				</v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="2">
          <v-select
            :items="cities"
            item-value="id"
            item-text="name"
            label="Cidade*"
            :loading="loading"
            :rules="requiredRules"
            v-model="form.city_id"
          ></v-select>
        </v-col>
        <v-col class="mt-5" cols="12" md="1">
          <a title="Adicionar Cidade" href="/#/cidades" target="_blank">
            <v-icon>
              mdi-plus
            </v-icon>
          </a>
          <v-icon title="Atualizar" @click="getCities">
            mdi-refresh
          </v-icon>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field label="CEP" v-model="form.postal_code"></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field label="Número" v-model="form.number"></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field label="Telefone" v-model="form.phone"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-textarea label="Endereço" v-model="form.address"></v-textarea>
        </v-col>
				<v-col cols="6">
          <v-textarea label="Observações" v-model="form.obs"></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn
            color="secondary"
            :loading="saving"
            block
            type="submit"
            >Salvar</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
	</div>
</template>

<script>
	export default {
		mounted() {
			this.getCities();
			this.getCustomer();
		},
		data() {
			return {
				loading: false,
				saving: false,
				requesting: false,
				cities: [],
				form: {},
				requiredRules: [(v) => !!v || "Este campo é obrigatório"],
				searching: false,
			}
		},
		methods: {
			getCustomer() {
				this.loading = true;
				this.$http
					.get(`api/customers/${this.$route.params.id}`)
					.then((res) => {
						this.form = res.data;
						this.loading = false;
					})
					.catch((err) => {
						this.loading = false;
						console.log(err);

						this.$side({
							type: 'error',
							msg: 'Não foi possível carregar o cliente',
							duration: 4000
						});
					});
			},
			getCities() {
				this.loading = true;
				this.$http
					.get("api/cities")
					.then((res) => {
						this.loading = false;
						this.cities = res.data;
					})
					.catch((err) => {
						this.loading = false;
						this.$side({
							type: "error",
							msg: "Erro ao carregar as cidades!",
						});
						console.log(err.data);
					});
			},
			save() {
				if (this.$refs.form.validate()) {
					this.saving = true;
					this.$http
						.put(`api/customers/${this.$route.params.id}`, this.form)
						.then((res) => {
							this.saving = false;
							this.$side({
								type: "success",
								msg: "Salvo com sucesso",
							});
						})
						.catch((error) => {
							let errors = Object.entries(error.response.data.errors);

							if (errors) {
								errors.forEach((e) => {
									this.$side({
										type: "error",
										msg: e[1][0],
										duration: 5000,
									});
								});
							}

							this.saving = false;
							this.$side({
								type: "error",
								msg: "Erro ao salvar",
							});
						});
				}
			},
		}
	}
</script>